import * as firebase from 'firebase/app';
import 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDlhOyL33ZpqQx0vN3pcqSoKZiaY2lpcZw",
  authDomain: "sourceable-back.firebaseapp.com",
  projectId: "sourceable-back",
  storageBucket: "sourceable-back.firebasestorage.app",
  messagingSenderId: "491044162632",
  appId: "1:491044162632:web:9d52197a21e4c8099f5c7d",
  measurementId: "G-KMZCWHMKRY"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
